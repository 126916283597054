import dayjs from 'dayjs';
import * as React from 'react';

import { IconDecorated } from 'components/icons';
import { sourcesDetails } from 'constants/sources-details';
import { SourceId } from 'types';

import { Task } from '../../hooks/useGroupTasks';
import { TaskDetailsContainer } from '../../styles';

type Props = {
    task: Task;
    onViewTaskDetails: (task: Task) => void;
};

const TaskExpandedContent = ({ task, onViewTaskDetails }: Props) => {
    const skElements = task.sk.split('_');

    return (
        <TaskDetailsContainer key={`task_${task.pk}_${task.sk}`}>
            <div>
                <IconDecorated sizeRem={1} name="AccessAlarmRounded" />
                <b>Finished at: </b>
                {dayjs.unix(Number(task.timestamp)).format('hh:mm:ss a')}
            </div>
            <div>
                <IconDecorated sizeRem={1} name="CloudRounded" />
                <b>Service: </b>
                {skElements[1]}
            </div>
            <div>
                <IconDecorated sizeRem={1} name="Language" /> <b>Source:</b>
                {sourcesDetails[task.sourceId as SourceId]?.label ?? task.sourceId}
            </div>
            <a
                href="#view-details"
                className="view-details"
                onClick={() => onViewTaskDetails(task)}
            >
                <span>View task details</span>
                <IconDecorated sizeRem={2} name="VisibilityOn" />
            </a>
        </TaskDetailsContainer>
    );
};

export default TaskExpandedContent;
