import MUICircularProgress, {
    CircularProgressProps,
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

import {
    ProgressBarLabelOutterWrapper,
    ProgressBarLabelInnerWrapper,
    ProgressBarLabel,
} from './styles';

export const CircularProgress = styled(
    ({ className, ...props }: CircularProgressProps & { value: number }) => (
        <MUICircularProgress {...props} />
    ),
)(() => ({
    [`& .${circularProgressClasses.circle}`]: {
        backgroundColor: 'var(--color-background)',
        color: 'var(--color-text)',
    },
}));

export const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => (
    <ProgressBarLabelOutterWrapper>
        <CircularProgress variant="determinate" {...props} />
        <ProgressBarLabelInnerWrapper>
            <ProgressBarLabel>{`${Math.round(props.value)}%`}</ProgressBarLabel>
        </ProgressBarLabelInnerWrapper>
    </ProgressBarLabelOutterWrapper>
);
export default CircularProgressWithLabel;
