import * as React from 'react';
import { Route } from 'type-route';

import { Game } from 'api/types/bff';
import { LiveFlags } from 'api/types/live-flags';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import ControlPanel from 'components/control-panel';
import Dropdown from 'components/dropdown';
import ExternalLinks from 'components/external-links';
import FlagStats from 'components/flag-stats';
import SourceSelector from 'components/source-selector';
import { useChangeParams } from 'hooks/useChangeParams';
import useExternalLinks from 'hooks/useExternalLinks';
import { ExternalSourceId, FlagType, SourceId } from 'types';

import useDiscrepancies from '../../hooks/useDiscrepancies';
import { useLimitOptions } from '../../hooks/useLimitOptions';
import { useLiveFlagCategories } from '../../hooks/useLiveFlagCategories';
import { useLiveFlagCategoryOptions } from '../../hooks/useLiveFlagCategoryOptions';
import { useLiveFlagCategoryTypeOptions } from '../../hooks/useLiveFlagCategoryTypeOptions';
import { useLiveFlagStatusOptions } from '../../hooks/useLiveFlagStatusOptions';
import { useLiveValuesBySource } from '../../hooks/useLiveValuesBySource';
import DiscrepancyControlBar from '../discrepancy-control-bar';
import DiscrepancyGrid from '../discrepancy-grid';

import { StatusConfigs } from './constants';
import { SectionContainer } from './styles';

type Props = {
    game: Game.LiveGame;
    isRefreshing: boolean;
    liveSource: ExternalSourceId;
    onRefreshClick: () => void;
    route: Route<typeof routes.gameBoxscoresGame>;
    updatedAt?: number;
};

const LiveGameSection = ({
    game,
    isRefreshing,
    liveSource,
    route,
    updatedAt = Date.now(),
    onRefreshClick,
}: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);

    const discrepancies = useDiscrepancies(game);

    const liveCategoriesQuery = useLiveFlagCategories(route.params.league, liveSource);
    const liveCategoriesOptions = useLiveFlagCategoryOptions(
        liveCategoriesQuery.data,
        route.params.liveCategory,
    );
    const liveTypeOptions = useLiveFlagCategoryTypeOptions(
        liveCategoriesQuery.data?.find((c) => c.categoryName === route.params.liveCategory)?.types,
        route.params.liveType,
    );

    const liveStatusOptions = useLiveFlagStatusOptions(route.params.liveStatus);
    const limitOptions = useLimitOptions(
        StatusConfigs[route.params.liveStatus].allowedLimits,
        route.params.liveLimit,
    );
    const valuesBySource = useLiveValuesBySource(leagueDetails.supportedLiveGameSources);

    const externalLinksQuery = useExternalLinks(
        {
            league: route.params.league,
            seasonType: route.params.seasonType,
            seasonYear: route.params.seasonYear,
            id: route.params.id,
            flagType: FlagType.LiveBoxscore,
        },
        true,
    );

    const awayTeamName = `${game.awayTeam?.market} ${game.awayTeam?.name}`;
    const homeTeamName = `${game.homeTeam?.market} ${game.homeTeam?.name}`;

    const handleParamsChange = useChangeParams(route);

    const handleSourceChange = React.useCallback(
        (source: SourceId) => handleParamsChange({ liveSource: source as ExternalSourceId }),
        [handleParamsChange],
    );
    const handleStatusChange = React.useCallback(
        (liveStatus?: LiveFlags.FlagStatus) => {
            handleParamsChange({
                liveStatus,
                ...(liveStatus &&
                    StatusConfigs[liveStatus].allowedLimits.length === 1 && {
                        liveLimit: undefined,
                    }),
            });
        },
        [handleParamsChange],
    );
    const handleCategoryChange = React.useCallback(
        (liveCategory?: LiveFlags.LiveFlagCategory) =>
            handleParamsChange({ liveCategory: liveCategory?.categoryName, liveType: undefined }),
        [handleParamsChange],
    );
    const handleTypeChange = React.useCallback(
        (liveType?: string) => handleParamsChange({ liveType }),
        [handleParamsChange],
    );
    const handleLimitChange = React.useCallback(
        (limit: number) => handleParamsChange({ liveLimit: limit }),
        [handleParamsChange],
    );

    return (
        <SectionContainer>
            <div className="header" data-testid="live-game-section-header">
                <div className="controls">
                    <SourceSelector
                        activeSource={liveSource}
                        valuesBySource={valuesBySource}
                        onSourceClick={handleSourceChange}
                    />
                    <ExternalLinks
                        isLoading={externalLinksQuery.isLoading}
                        linksInfo={externalLinksQuery.data ?? []}
                    />
                    <DiscrepancyControlBar
                        defaultSource={liveSource}
                        isRefreshing={isRefreshing}
                        onRefreshClick={onRefreshClick}
                        teams={[
                            { id: game.awayTeam?.srId, name: awayTeamName },
                            { id: game.homeTeam?.srId, name: homeTeamName },
                        ]}
                        updatedAt={new Date(updatedAt).toISOString()}
                    />
                </div>
                <FlagStats flagged={game.flagStats.CREATED} ignored={game.flagStats.IGNORED} />
                <ControlPanel>
                    <ControlPanel.Group>
                        <Dropdown
                            label="Status"
                            options={liveStatusOptions}
                            onChange={handleStatusChange}
                        />
                        <Dropdown
                            label="Stat Category"
                            options={liveCategoriesOptions}
                            onChange={handleCategoryChange}
                        />
                        <Dropdown
                            label="Stat Type"
                            searchable
                            options={liveTypeOptions}
                            onChange={handleTypeChange}
                        />
                    </ControlPanel.Group>
                    <ControlPanel.Group>
                        {StatusConfigs[route.params.liveStatus].allowedLimits.length > 1 && (
                            <Dropdown
                                label="Page Size"
                                options={limitOptions}
                                onChange={handleLimitChange}
                            />
                        )}
                    </ControlPanel.Group>
                </ControlPanel>
            </div>
            <DiscrepancyGrid
                league={route.params.league}
                awayTeamName={awayTeamName}
                awayTeamGlobalSrId={game.awayTeam?.srGlobalId}
                homeTeamName={homeTeamName}
                homeTeamGlobalSrId={game.homeTeam?.srGlobalId}
                category={route.params.liveCategory}
                discrepancies={discrepancies}
            />
        </SectionContainer>
    );
};

export default LiveGameSection;
