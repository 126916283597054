import styled from '@emotion/styled';

export const ProgressBarLabelOutterWrapper = styled.div`
    position: relative;
    display: inline-flex;
    transform: scale(0.9);
`;
export const ProgressBarLabelInnerWrapper = styled.div`
    top: 0;
    left: -0.1875rem;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const ProgressBarLabel = styled.div`
    font-size: 0.7rem;
    font-weight: bold;
    color: var(--color-text);
`;
