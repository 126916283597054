import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';

import { ViewType } from '../../types';

type Props = {
    awayTeamId?: string;
    awayTeamAlias?: string;
    homeTeamId?: string;
    homeTeamAlias?: string;
    gameView: ViewType;
    onChange: (gameView: ViewType) => void;
};

const GameViewTabs = ({
    awayTeamId,
    awayTeamAlias = 'Away',
    homeTeamId,
    homeTeamAlias = 'Home',
    gameView,
    onChange,
}: Props) => {
    return (
        <TabMenu>
            <TabButton
                active={gameView === ViewType.LIVE_GAME}
                onClick={() => onChange(ViewType.LIVE_GAME)}
            >
                Live Game
            </TabButton>
            <TabButton
                active={gameView === ViewType.POST_GAME}
                onClick={() => onChange(ViewType.POST_GAME)}
            >
                Post Game
            </TabButton>
            <div style={{ flexGrow: 1 }} />
            {awayTeamId && (
                <TabButton
                    active={gameView === ViewType.AWAY_MAPPINGS}
                    onClick={() => onChange(ViewType.AWAY_MAPPINGS)}
                >
                    {`${awayTeamAlias} - Mapping`}
                </TabButton>
            )}
            {homeTeamId && (
                <TabButton
                    active={gameView === ViewType.HOME_MAPPINGS}
                    onClick={() => onChange(ViewType.HOME_MAPPINGS)}
                >
                    {`${homeTeamAlias} - Mapping`}
                </TabButton>
            )}
        </TabMenu>
    );
};

export default GameViewTabs;
